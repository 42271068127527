body,
html {
  padding: 20px;
}

p {
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
}

button {
  padding: 10px 30px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.note {
  margin: 0;
  font-size: 13px;
  color: red;
}